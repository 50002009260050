function InfoCardController(
  $mdDialog,
  $filter,
  $scope,
  $log,
  jobTracker,
  User,
  userProfile,
  jsyaml,
  searchQuery
) {
  this.deleting = false;
  this.deletionSuccess = false;
  this.deletionErr = "";

  this.jobInputQuery = null;
  this.searchConfigYaml = null;

  this.profile = userProfile;

  this.config = null;

  this.$onChanges = (cObject) => {
    if (cObject.job && cObject.job.currentValue) {
      const job = cObject.job.currentValue;
      // outputFileNames will not be available immediately after page load
      // or for jobs submitted from a query
      this.config = job.outputFileNames && job.outputFileNames.config;

      this.inputQueryConfig = null;
      this.searchConfigYaml = null;
      if (job.inputQueryConfig && job.inputQueryConfig.queryBody) {
        if (typeof job.inputQueryConfig.queryBody === "string") {
          this.inputQueryConfig = JSON.parse(job.inputQueryConfig.queryBody);

          this.jobInputQuery = searchQuery.getQuery(this.inputQueryConfig);
        } else {
          this.inputQueryConfig = job.inputQueryConfig.queryBody;

          this.jobInputQuery = searchQuery.getQuery(this.inputQueryConfig);
        }
      }
    }
  };

  //Memoized time getting function
  const attemptTime = {};
  this.getTime = (type, attempt, start, end) => {
    if (attemptTime[type] && attemptTime[type][attempt]) {
      return attemptTime[type][attempt];
    }

    if (!attemptTime[type]) {
      attemptTime[type] = {};
    }

    attemptTime[type][attempt] = $filter("msToTime")(new Date(end).getTime() - new Date(start).getTime());

    return attemptTime[type][attempt];
  };

  this.updating = false;
  this.updateSuccess = false;
  this.updateErr = false;

  this.updateJob = (type, prop, val, ev) => {
    if (type === undefined) {
      type = "update";
    }

    if (type !== "update" && type !== "delete") {
      this.updateErr = new Error("Type must be one of 'update' or 'delete'");
      return;
    }

    let textContent;
    if (val === "public") {
      textContent = 'This job will be visible to the world under the "Public" tab';
    } else {
      textContent = "This job will be visible only to this account";
    }

    if (prop == "visibility") {
      const confirm = $mdDialog
        .confirm()
        .title("Make this job " + val + "?")
        .textContent(textContent)
        .ariaLabel("Change privacy settings")
        .targetEvent(ev)
        .ok("Make " + val)
        .cancel("Cancel");

      $mdDialog.show(confirm).then(
        () => _updateJob(prop, val),
        () => { }
      );

      return;
    }

    return _updateJob(prop, val);
  };

  const _updateJob = (prop, val) => {
    this.updating = true;

    const updateProp = {};
    updateProp[prop] = val;

    jobTracker
      .patch(this.job, updateProp)
      .then(
        (updatedJob) => {
          this.updateSuccess = true;
          this.onUpdated();
        },
        (err) => {
          this.updateErr = err;
        }
      )
      .finally(() => {
        this.updating = false;

        // Don't clear errors
        // TODO: handle clearing errors on click
      });
  };

  this.deleteJob = (ev) => {
    const confirm = $mdDialog
      .confirm()
      .title("Delete Job")
      .textContent(`Clicking "Delete" will remove all data associated with this job.`)
      .ariaLabel(`Delete this job: ${this.job.name}`)
      .targetEvent(ev)
      .ok("Delete")
      .cancel("Cancel");

    $mdDialog.show(confirm)
      .then(() => {
        this.deleting = true;

        // Notify consumers that the job deletion was handled here, to avoid
        // unnecessary dialogs.
        this.onDeleting();

        ev.stopImmediatePropagation();
        this.job
          .$remove(
            (job) => {
              // This isn't strictly necessary; jobs.events.service should pick this up
              // It is more of a precaution against socket.io failure
              // In any case jobTracker additions are idempotent, so no big deal
              [this.err, this.job] = jobTracker.trackJobUpdate(job);

              this.deletionSuccess = true;
              this.onDeleted();
            },
            (err) => {
              this.deletionErr = err;
            }
          )
          .finally(() => {
            this.deleting = false;

            // Don't clear errors
            // TODO: handle clearing errors on click
          });
      })
      .catch((rejection) => {
        $log.error(rejection);
      });
  };

  this.showFullSearchConfig = ($event) => {
    if (!this.config) {
      $log.warn("No config to download");
      return;
    }

    // Appending dialog to document.body to cover sidenav in docs app
    // Modal dialogs should fully cover application
    // to prevent interaction outside of dialog
    $mdDialog.show({
      parent: angular.element(document.querySelector("#deleted-popup")),
      template: `<md-dialog aria-label='Saved Query' flex='80' style='max-width:50%; max-height:70%'>
            <md-toolbar>
              <div class='md-toolbar-tools'>
                <h1>Saved Query</h1>
                <span flex></span>
                <md-button
                aria-label='Close' class='md-icon-button'
                ng-click='cancel()'>
                  <md-icon class='material-icons'>close</md-icon>
                </md-button>
              </div>
            </md-toolbar>
            <md-dialog-content>
              <div class='md-dialog-content' layout='column'>
                <div style='white-space: pre-wrap;'>{{inputQueryConfig | json}}<div>
              </div>
            </md-dialog-content>
          </md-dialog>
         `,
      locals: {
        inputQueryConfig: this.inputQueryConfig,
      },
      controller: function dialogController($scope, $mdDialog, inputQueryConfig) {
        $scope.inputQueryConfig = inputQueryConfig;

        $scope.cancel = $mdDialog.hide;
      },
    });
  };

  this.downloadYamlConfig = () => {
    if (!this.config) {
      $log.warn("No config to download");
      return;
    }

    var element = document.createElement("a");
    element.setAttribute(
      "href",
      "data:text/plain;charset=utf-8," +
      encodeURIComponent(
        jsyaml.safeDump(this.config, {
          sortKeys: true,
          noRefs: true,
          noCompatMode: true,
        })
      )
    );
    element.setAttribute("download", `${this.config.name || this.config.assembly}.v${this.config.version}.yaml`);
    element.target = "_self"; //may be needed for firefox
    element.style.display = "none";
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  };

  const userQuery = User.getAllUsers();

  this.showUsers = (ev) => {
    $mdDialog
      .show({
        controller: DialogController,
        templateUrl: "jobs/jobs.share.modal.tpl.html",
        locals: { job: this.job, userQuery: userQuery },
        parent: angular.element(document.body),
        targetEvent: ev,
        clickOutsideToClose: true,
        fullscreen: true,
        isolateScope: true,
      })
      .then((job) => {
        _updateJob("sharedWith", job.sharedWith);
      })
      .catch((rejection) => {
        $log.error(rejection);
      });
  };

  function DialogController($scope, $mdDialog, job, userQuery, userProfile) {
    $scope.loading = true;
    $scope.job = null;
    $scope.fuse_user_results = [];
    $scope.checkboxes_read = {};
    $scope.checkboxes_write = {};
    $scope.allRead = false;
    $scope.allWrite = false;

    let users = null;
    const user_query_partial = userQuery.$promise
      .then((u) => {
        users = u.filter(thisUser => thisUser._id !== job.userId);
        const options = {
          includeScore: true,
          shouldSort: true,
          threshold: 0.5,
          useExtendedSearch: true,
          keys: ["email", "name"],
        };

        let allReadMaybe = true;
        let allWriteMaybe = true;

        for (let idx in users) {
          const user_id = users[idx]._id;
          const user_name = users[idx].name;

          if (!(user_id in job.sharedWith)) {
            allReadMaybe = false;
            allWriteMaybe = false;
            break;
          }

          if (job.sharedWith[user_id] !== 400 && job.sharedWith[user_id] !== 600) {
            console.error(`Unknown job sharing value for user ${user_name}`);
            allReadMaybe = false;
            allWriteMaybe = false;
            break;
          }

          if (job.sharedWith[user_id] === 400) {
            allWriteMaybe = false;
            continue;
          }
        }

        $scope.allRead = allReadMaybe;
        $scope.allWrite = allWriteMaybe;

        $scope.loading = false;
        $scope.fuse_user_results = users;

        return new Fuse(u, options);
      })
      .catch((err) => {
        $log.error(err);
        $scope.userFetchError = err;
        $scope.loading = false;
        $scope.fuse_user_results = [];
      });

    if (!("sharedWith" in job)) {
      job['sharedWith'] = {};
    } else {
      for (let userId in job.sharedWith) {
        if (job.sharedWith[userId] == 400) {
          $scope.checkboxes_read[userId] = true;
          $scope.checkboxes_write[userId] = false;
        } else if (job.sharedWith[userId] == 600) {
          $scope.checkboxes_read[userId] = true;
          $scope.checkboxes_write[userId] = true;
        } else {
          console.error(`Unknown scope value ${job.sharedWith[userId]}`);
        }
      }
    }

    $scope.job = job;

    $scope.onCancelled = () => {
      $mdDialog.cancel();
    };

    $scope.onSelected = () => {
      $mdDialog.hide($scope.job);
    };

    $scope.querySearch = (query) => {
      if (!query) {
        $scope.fuse_user_results = users;
        return users;
      }
      return user_query_partial.then((fuse_users) => {
        const s = fuse_users.search(query);

        $scope.fuse_user_results = [];
        s.forEach((item) => {
          if (item.item._id === job.userId) {
            return;
          }

          $scope.fuse_user_results.push({
            name: item.item.name,
            _id: item.item._id,
            email: item.item.email,
          });
        });
      });
    };

    $scope.updatePermissions = (user, permission_value, type) => {
      if (!(type === "read" || type === "write")) {
        console.error("only accept types 'read' and 'write'");
        return;
      }

      let value = 0;
      if (permission_value === true) {
        if (type === "read") {
          value = 400;
          $scope.checkboxes_read[user._id] = true;
          $scope.checkboxes_write[user._id] = false;
        } else if (type === "write") {
          value = 600;
          $scope.checkboxes_read[user._id] = true;
          $scope.checkboxes_write[user._id] = true;
        }
      }

      let current_value = job.sharedWith[user._id] || 0;

      if (permission_value === false) {
        if (type === "write") {
          if (current_value === 600) {
            value = 400;
            $scope.checkboxes_write[user._id] = false;
          }
        } else if (type === "read") {
          value = 0;
          $scope.checkboxes_read[user._id] = false;
          $scope.checkboxes_write[user._id] = false;
        }
      }
      if (!value) {
        delete job.sharedWith[user._id];
      } else {
        if (value < 400 || value > 600) {
          console.error(`${value} is an invalid value`);
        } else {
          job.sharedWith[user._id] = value;
        }
      }
    };

    $scope.updateAllPermissions = (type, permission_value) => {
      if (!(type === "read" || type === "write")) {
        console.error("only accept types 'read' and 'write'");
        return;
      }

      if (!(permission_value === true || permission_value === false)) {
        console.error("only accept permission_value 'true' and 'false'");
        return;
      }

      let value = 0;
      if (permission_value === true) {
        if (type === "read") {
          value = 400;
        } else if (type === "write") {
          value = 600;
          $scope.allRead = true;
          $scope.allWrite = true;
        }
      }

      if (permission_value === false) {
        if (type === "read") {
          $scope.allWrite = false;
          $scope.allRead = false;
        } else if (type === "write") {
          $scope.allRead = true;
          $scope.allWrite = false;
        }
      }

      for (let idx in users) {
        const user_id = users[idx]._id;
        let current_value = job.sharedWith[user_id] || 0;

        if (permission_value === true) {
          if (current_value < value) {
            job.sharedWith[user_id] = value;

            if (value === 600) {
              $scope.checkboxes_read[user_id] = true;
              $scope.checkboxes_write[user_id] = true;
            } else if (value === 400) {
              $scope.checkboxes_read[user_id] = true;
              $scope.checkboxes_write[user_id] = false;
            }
          }

          continue;
        }

        if (type === "write") {
          if (current_value === 600) {
            job.sharedWith[user_id] = 400;
            $scope.checkboxes_write[user_id] = false;
          }
        } else if (type === "read") {
          delete job.sharedWith[user_id];
          $scope.checkboxes_read[user_id] = false;
          $scope.checkboxes_write[user_id] = false;
        }
      }
    };
  }
}

angular
  .module("sq.jobs.infoCard.component", [
    "sq.user.model",
    "sq.jobs.tracker.service",
    "sq.jobs.events.service",
    "sq.user.profile.service",
    "sq.jobs.results.search.query.service",
  ])
  .component("sqJobInfoCard", {
    bindings: {
      // The submission object (could be search or main job submission)
      job: "<",
      numSamples: "<",
      jobStats: "<",
      onBack: "&",
      cardWidth: "@",
      onDeleting: "&",
      onDeleted: "&",
      onUpdated: "&",
    }, // isolate scope
    templateUrl: "jobs/jobs.infoCard.tpl.html",
    controller: InfoCardController,
    controllerAs: "$ctrl",
    transclude: {
      headerActions: "?headerActions",
      cardContent: "?cardContent",
      menuOptions: "?menuOptions",
    },
  });
